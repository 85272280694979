<script setup lang="ts">
import { useStorage } from '@vueuse/core'
import type { Quote } from '~/types/interfaces';

const sessionQuote = useStorage<Quote>("quote", <Quote>{}, sessionStorage);
sessionQuote.value = null;

const { isCgvOpen } = useDashboard()
</script>
<template>
    <div>
        <slot />
        <ModalsCgv v-model="isCgvOpen"/>
    </div>
</template>